import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FrequentlyAskedQuestionProps from "./FrequentlyAskedQuestionProps";

const FrequentlyAskedQuestion = loadable(
    (): Promise<DefaultComponent<FrequentlyAskedQuestionProps>> =>
        import(
            /* webpackChunkName: "FrequentlyAskedQuestion" */ "./FrequentlyAskedQuestion"
        ),
);
const FrequentlyAskedQuestionLoader = (
    props: FrequentlyAskedQuestionProps,
): ReactElement => <FrequentlyAskedQuestion {...props} />;
export default FrequentlyAskedQuestionLoader;
